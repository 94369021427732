import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: grid;
  margin-top: 16px;
  .title-risk {
    font-family: 'Texta';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 34px;
    color: #4b5166;
  }
  .grid {
    display: grid;
    grid-template-columns: 0.45fr 3fr;
    height: 100%;
    gap: 16px;
  }

  .item-grid {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;
  }

  .sub-item {
  }

  .sub-item-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 16px;
  }

  .inner-item-grid {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;
  }

  .line-item-grid {
    display: grid;
    grid-template-columns: 1.6fr 1fr;
    gap: 16px;
  }

  .column-item {
    padding: 5px;
  }

  .style-container {
    border-radius: 4px;
    border: 1px solid #95aac94d;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    overflow-x: auto;
  }
  .container-bar-grafico {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    padding-top: 30px;
  }
  .container-centered-column {
    display: flex;
    flex-direction: column;
    //align-items: center;
    padding-left: 30px;
    .title {
      font-size: 24px;
      font-weight: 900;
      letter-spacing: 0px;
      color: #4b5166;
      margin-top: 16px;
    }
    .sub-title {
      text-align: left;
      font-size: 16px;
      font-weight: medium;
      letter-spacing: 0px;
      color: #4b5166;
    }
    .sub-title > span {
    }
    .sub-container {
      height: 106px;
      border-left: 3px solid #95aac94d;
      border-radius: 4px;
      padding: 10px;
      margin-top: 16px;
      .text1 {
        text-align: left;
        font-size: 16px;
        font-weight: medium;
        letter-spacing: 0px;
        color: #4b5166;
      }
      .text2 {
        text-align: left;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 0px;
        color: #4b5166;
      }
    }
  }
`;
