import React from 'react';

// components
import Divider from '@mui/material/Divider';
import { Switch } from 'components/Inputs/Switch';

// icons
import { DesvioItem, Item } from './styled';

import { getColor, formatArrayString } from '../services';
import {
  disableEscalation,
  enableEscalation,
  saveEscalation,
} from '../../services';
import { toast } from 'react-toastify';

export const DefaultTorre = ({
  item,
  desviosTiposPadroes,
  desviosTipos,
  refetch,
}) => {
  const handleSwitchChange = async (item, value) => {
    console.log(item);
    if (item?.id) {
      const res = value
        ? await enableEscalation(item.id)
        : await disableEscalation(item.id);
      if (res?.success) {
        toast.success(res.message);
      } else {
        toast.error('Falha ao atualizar lista.');
      }
    } else {
      const payload = {
        ...item,
        is_default: true,
        ids_desvios: desviosTiposPadroes.map(dt => dt.id),
        status: 'ATIVO',
        prazo: null,
        etapas: [],
      };
      const res = await saveEscalation(payload);
      if (res.success) {
        toast.success('Lista salva com sucesso');
      } else {
        toast.error(res.error?.message ?? res.message);
      }
    }

    refetch();
  };

  return (
    <>
      <Item>
        <Switch
          check={item.status === 'ATIVO'}
          setCheckbox={value => handleSwitchChange(item, value)}
          textOn=""
          textOff=""
        />
        <Item>
          {item?.is_default && 'Onisys - '}
          <span style={{ color: getColor(item?.risco) }}>
            {`${item?.titulo.slice(0, 50)}` || ''}
          </span>
        </Item>
      </Item>
      <DesvioItem>
        <div className="title">Emitir alertas para:</div>
        <div className="desvios-padroes">
          Para o rastreamento de deslocamentos de risco, são analisados dados
          referentes aos desvios que são os maiores geradores de acidentes,
          incluindo{' '}
          <span>
            {' '}
            {formatArrayString(
              item.is_default ? desviosTiposPadroes : desviosTipos,
              item?.ids_desvios,
              ' / ',
            )}
          </span>
        </div>
      </DesvioItem>
      <Divider style={{ marginTop: 15 }} />
    </>
  );
};
