import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { subDays } from 'date-fns';
import DateRangePicker from '../DateRangePicker2';
import SelectMultiple from 'components/Inputs/_SelectMultiple';
import {
  Container,
  ContainerSelects,
  ButtonApply,
  FiltersContainer,
  ButtonCustom,
  PopoverContaiener,
  ButtonIconCustom,
} from './styled';

import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import { toast } from 'react-toastify';
import Tabs from './Tabs';
import { usePlans } from 'hooks/usePlans';

import { ReactComponent as IconExportar } from '../../images/icons/components/exportar.svg';
import { ReactComponent as IconFilters } from '../../images/icons/components/filters.svg';
import { ReactComponent as IconArrows } from '../../images/icons/components/arrows.svg';
import { ReactComponent as IconClear } from '../../images/icons/components/clear.svg';
import { ReactComponent as IconReflesh } from '../../images/icons/components/reflesh.svg';

const FiltersGlobal = ({
  data = [],
  isFetching = false,
  handleExport = () => {},
  refetch = () => {},
  handleFilters = () => {},
  customComponent = <></>,
  hideExportButton = false,
  hideRefleshButton = false,
  enablePeriodFilters = false,
  handlePeriodFilter = () => {},
  persistDate = false,
  selectedFilter = false,
  showHours = false,
  defaultDate = {
    initialDate: subDays(new Date(), 30),
    finalDate: new Date(),
  },
}) => {
  const { isProvider } = usePlans();
  const selectEmpresas = useSelector(state => state.selects.empresas);

  const tabsDates = [
    { value: 'day', label: 'D' },
    { value: 'week', label: 'S' },
    { value: 'month', label: 'M' },
  ];

  const [isFilterSelect, setIsFilterSelect] = useState(selectedFilter);
  const [filters, setFilters] = useState({});

  const listRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [clearFilters, setClearFilters] = useState(false);
  const [usedData, setUsedData] = useState(data);

  useEffect(() => {
    const list = listRef.current;
    setIsOverflowing(list.scrollWidth > list.clientWidth);
    if (isProvider) {
      const empresas = filters?.empresas;
      if (empresas?.length) {
        const filteredEmpresas = selectEmpresas?.filter(item =>
          empresas.includes(item.id),
        );
        const newFiliais = filteredEmpresas.flatMap(item =>
          item?.filiais.map(filial => ({
            label:
              empresas?.length > 1
                ? `(${item?.nome}) ${filial?.nome}`
                : `${filial?.nome}`,
            value: filial?.id,
          })),
        );

        setUsedData(
          usedData.map(item => {
            if (
              item.filterName === 'filial' ||
              item.filterName === 'filial_veiculo'
            ) {
              item.options = newFiliais;
            }
            return { ...item };
          }),
        );
      }
    }
  }, [filters]);

  const handleClick = newPlacement => event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleReflesh = () => {
    refetch();
  };

  const handleFilterChange = (filterName, ids) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: ids,
    }));
  };

  const handleDateChange = date => {
    handleFilters(date);
    setFilters(prevFilters => ({
      ...prevFilters,
      ...date,
    }));
  };

  const handleApplyFilters = () => {
    handleFilters(filters);
    toast.success('Filtro de data aplicado com sucesso!');
  };

  return (
    <Container>
      <Popper
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <PopoverContaiener>
              <div className="text">Deseja resetar todos os filtros?</div>
              <div className="container-btn">
                <ButtonCustom onClick={() => setOpen(false)}>
                  Cancelar
                </ButtonCustom>
                <ButtonApply
                  onClick={() => {
                    setClearFilters(true);
                    setTimeout(() => setClearFilters(false), 100);
                    setOpen(false);
                    handleFilters(filters);
                    toast.success('Filtros resetados com sucesso!');
                  }}
                >
                  Aplicar
                </ButtonApply>
              </div>
            </PopoverContaiener>
          </Fade>
        )}
      </Popper>
      <FiltersContainer>
        <div className="container-controls">
          {!hideRefleshButton && (
            <ButtonIconCustom
              opacity
              isRotating={isFetching}
              onClick={() => handleReflesh()}
            >
              <IconReflesh />
            </ButtonIconCustom>
          )}
          {customComponent}
        </div>

        <div className="btn-right-container">
          {enablePeriodFilters && (
            <Tabs tabs={tabsDates} handleApplay={handlePeriodFilter} />
          )}
          <ButtonIconCustom opacity onClick={handleClick('bottom-end')}>
            <IconClear />
          </ButtonIconCustom>
          <ButtonCustom
            opacity
            endIcon={
              <IconArrows
                style={{
                  transform: isFilterSelect ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease-in-out',
                }}
                fill={isFilterSelect ? '#fff' : '#95AAC9'}
              />
            }
            selected={isFilterSelect}
            onClick={() => setIsFilterSelect(p => !p)}
          >
            <IconFilters fill={isFilterSelect ? '#fff' : '#95AAC9'} />
          </ButtonCustom>
          <DateRangePicker
            showHours={showHours}
            dInt={defaultDate.initialDate}
            dfim={defaultDate.finalDate}
            onDate={handleDateChange}
            persistDate={persistDate}
          />
          {!hideExportButton && (
            <ButtonCustom startIcon={<IconExportar />} onClick={handleExport}>
              Exportar
            </ButtonCustom>
          )}
        </div>
      </FiltersContainer>
      <ContainerSelects isOpen={isFilterSelect}>
        <div className="filter-list-description">Filtrar por:</div>
        <div className="container-list" ref={listRef}>
          <div className="container-select">
            {!isProvider &&
              data.map(item => (
                <div className="list-selects" key={item?.filterName}>
                  <SelectMultiple
                    multiple={item?.multiple}
                    clearSelected={clearFilters}
                    data={item?.options || []}
                    filialSubTitle={`Filtrar por ${item?.label.toLowerCase()}`}
                    value={filters[item?.filterName] || []}
                    filialName={item?.label}
                    handleChange={val =>
                      handleFilterChange(item?.filterName, val)
                    }
                  />
                </div>
              ))}
            {isProvider &&
              usedData.map(item => (
                <div className="list-selects" key={item?.filterName}>
                  <SelectMultiple
                    multiple={item?.multiple}
                    clearSelected={clearFilters}
                    data={item?.options || []}
                    filialSubTitle={`Filtrar por ${item?.label.toLowerCase()}`}
                    value={filters[item?.filterName] || []}
                    filialName={item?.label}
                    handleChange={val =>
                      handleFilterChange(item?.filterName, val)
                    }
                  />
                </div>
              ))}
          </div>
          <ButtonApply onClick={() => handleApplyFilters()}>
            Aplicar tudo
          </ButtonApply>
        </div>
      </ContainerSelects>
    </Container>
  );
};

export default FiltersGlobal;
