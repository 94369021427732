import React, { useCallback } from 'react';

import { Divider } from '@mui/material';
import Tabs from 'components/Tabs';
import InfoCard from 'components/Cards/InfoCard';
import Header from './components/Header';
import Cards from './components/Cards';
import { DefaultTable } from 'components/_Table/templates/default';
import { FilterTable } from 'components/_Table/templates/filter';
import { DoubleInputTable } from 'components/_Table/templates/doubleInput';

const ListPage = ({
  title,
  headerActions,
  headerTabs = null,
  infoMessage = null,
  cards = null,
  selectedCard = null,
  disableCards = false,
  loadingCards = false,
  handleClickCard = null,
  tableProps = {},
  tableTemplate = 'default',
  customComponent,
}) => {
  const renderTable = useCallback((tableTemplate, tableProps) => {
    switch (tableTemplate) {
      case 'filter':
        return <FilterTable {...tableProps} />;
      case 'double':
        return <DoubleInputTable {...tableProps} />;
      default:
        return <DefaultTable {...tableProps} />;
    }
  }, []);

  return (
    <>
      <Header title={title} actions={headerActions} />

      {customComponent}

      {headerTabs && (
        <>
          <Tabs {...headerTabs} />
          <Divider />
        </>
      )}

      {infoMessage && <InfoCard message={infoMessage} key="info" />}

      {!!cards && (
        <Cards
          cards={cards}
          selectedCard={selectedCard}
          disableCards={disableCards}
          loadingCards={loadingCards}
          handleClickCard={handleClickCard}
        />
      )}

      {renderTable(tableTemplate, tableProps)}
    </>
  );
};

export default ListPage;
