/*
Parte lateral esquerda do componente
Definição de períodos pré-selecionados.
Ex: 'Últimos 7 dias'
*/

import {
  addDays,
  startOfDay,
  startOfMonth,
  startOfYear,
  endOfMonth,
  addMonths,
  isSameDay,
  endOfDay,
} from 'date-fns';

const maxDate = startOfDay(new Date());
const minDate = new Date(maxDate.getFullYear(), maxDate.getMonth() - 11, 1);

// Ano atual se data atual já está em abril ou mais
// Ano passado se não
const startYearSafra =
  maxDate.getMonth() > 2 ? maxDate.getFullYear() : maxDate.getFullYear() - 1;

const defineds = {
  today: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfMonth: startOfMonth(new Date()),
  startOfYear: startOfYear(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  last30: startOfDay(addDays(new Date(), -30)),
  startSafra: new Date(startYearSafra, 3, 1, 0, 0, 0),
  startLastSafra: new Date(startYearSafra - 1, 3, 1, 0, 0, 0),
  endLastSafra: new Date(startYearSafra, 3, 0, 0, 0, 0),
};

// Não mexer (comportamento do componente)
const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

// Não mexer (comportamento do componente)
function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

// Períodos
export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Hoje',
    range: () => ({
      startDate: defineds.today,
      endDate: defineds.endOfToday,
      isLabel: true,
    }),
  },
  {
    label: 'Últimos 30 dias',
    range: () => ({
      startDate: defineds.last30,
      endDate: defineds.endOfToday,
      isLabel: true,
    }),
  },
  {
    label: 'Últimos 12 meses',
    range: () => ({
      startDate: minDate,
      endDate: defineds.endOfToday,
      isLabel: true,
    }),
  },
  {
    label: 'Mês atual',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfToday,
      isLabel: true,
    }),
  },
  {
    label: 'Mês anterior',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
      isLabel: true,
    }),
  },
  {
    label: 'Ano safra atual',
    range: () => ({
      startDate: defineds.startSafra,
      endDate: defineds.endOfToday,
      isLabel: true,
    }),
  },
  {
    label: 'Último ano safra',
    range: () => ({
      startDate: defineds.startLastSafra,
      endDate: defineds.endLastSafra,
      isLabel: true,
    }),
  },
]);
