import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useParams } from 'react-router-dom';

import { Grid, Divider } from '@mui/material';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';
import SelectCliente from 'components/Inputs/SelectCliente';
import SelectInput from 'components/Inputs/Select';
import Calendar from 'components/Inputs/Calendar';
import TextInput from 'components/Inputs/TextField';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import SelectMultiple from '../../../Configuracoes/EscalationList/NovaLista/components/SelectMultiple';

import * as S from '../styled';
import { getTipoDesvio } from '../../constants';
import { usePlans } from 'hooks/usePlans';
import { useQuery } from 'react-query';
import { getResponsaveisProvider } from '../../components/DesvioManualModal/services';
import { requestNiveis } from 'pages/Configuracoes/Usuarios/services';
import { getResponsaveisDesvio } from '../../services';

const objetos = [
  { name: 'BEBIDA', value: 'BEBIDA' },
  { name: 'ALIMENTO', value: 'ALIMENTO' },
  { name: 'CIGARRO', value: 'CIGARRO' },
  { name: 'OUTRO', value: 'OUTRO' },
];

const grupos = [
  { value: 1, label: 'Administrador' },
  { value: 2, label: 'Analista' },
  { value: 3, label: 'Operacional' },
  { value: 4, label: 'Convidado' },
];

const Config = ({
  infraction,
  formData,
  setFormData,
  handleOpenModal,
  setDesvioDeleteModal,
  setDesvioRetornoModal,
  setOpenRevisaoExclusao,
  handleChangeDistribuidora,
}) => {
  const theme = useTheme();
  const params = useParams();

  const { isOpLogistico, isProvider, hasTorrePlus } = usePlans();
  const user = useSelector(state => state.auth.user?.user);
  const userLevel = user.nivel;
  const userFilials = user.usuario_filiais;
  const [responsaveis, setResponsaveis] = useState([]);
  const { users, clients } = useSelector(state => {
    return state.selects;
  });

  const [selectFator, setSelectFator] = useState(
    infraction?.desvio_info?.fator_risco
      ? ['Jornada excedida', 'Descanso não cumprido'].includes(
          infraction.desvio_info.fator_risco,
        )
        ? infraction.desvio_info.fator_risco
        : 'Outros'
      : '',
  );

  const [selectObjeto, setSelectObjeto] = useState(
    infraction?.objeto
      ? objetos.some(obj => obj.value === infraction.objeto)
        ? infraction.objeto
        : 'OUTRO'
      : '',
  );

  const { data: dataSelects } = useQuery(
    ['responsaveis-desvio'],
    () => getResponsaveisDesvio(),
    {
      onSuccess: (data = []) => {
        if (data.length !== 0) {
          setResponsaveis(data);
        }
      },
      enabled: !user.provider,
      refetchOnWindowFocus: false,
    },
  );

  const handleChange = (name, value) => {
    setFormData(prev => {
      return { ...prev, [name]: value };
    });
  };

  const { data: responsaveisProvider = [] } = useQuery(
    ['responsaveis-provider'],
    () => getResponsaveisProvider(),
    {
      onSuccess: (data = []) => {
        if (data.length !== 0) {
          setResponsaveis(
            data.map(item => ({
              id: item.id,
              value: item.nome,
              grupo: item.nivel,
            })),
          );
        }
      },
      enabled: user.provider,
      refetchOnWindowFocus: false,
    },
  );

  const tipoDesvio = getTipoDesvio(infraction);

  return (
    <S.ColumnWrapper>
      <Grid container columnSpacing={2} rowSpacing={1}>
        {!hasTorrePlus ? (
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            {isProvider ? (
              <>
                <div className="imageArea">
                  {infraction.distribuidora.logo_url && (
                    <img src={infraction.distribuidora.logo_url} />
                  )}
                  {!infraction.distribuidora.logo_url && (
                    <span className="letters">
                      {infraction.distribuidora.nome[0]}
                    </span>
                  )}
                </div>
                <span className="distributorName">
                  {infraction.distribuidora.nome}
                </span>
              </>
            ) : (
              <>
                <SelectCliente
                  value={formData?.id_distribuidora}
                  setValue={val => handleChangeDistribuidora(val)}
                  distributors={clients}
                  disabled={
                    infraction.status !== 'ABERTO' ||
                    infraction.revisao_da_operacao === 'PENDENTE' ||
                    infraction.aprovado_distribuidora === 0 ||
                    infraction.aprovado_distribuidora === 3 ||
                    userLevel > 3
                  }
                />
                {infraction.revisao_da_operacao === 'PENDENTE' && (
                  <>
                    <KeyboardDoubleArrowRight
                      sx={{
                        color: theme.palette.brand.secondary.natural,
                        opacity: 0.6,
                        margin: '20px 20px 0px 20px',
                      }}
                      fontSize="large"
                    />

                    <SelectCliente
                      value={infraction.alteracao_distribuidora}
                      distributors={clients}
                      disabled
                    />
                  </>
                )}
              </>
            )}
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
            marginLeft="-5%"
          >
            <div className="imageArea">
              {infraction.empresa?.logo_url && (
                <img src={infraction.empresa.logo_url} />
              )}
              {infraction.empresa && !infraction.empresa.logo_url && (
                <span className="letters">{infraction.empresa.nome[0]}</span>
              )}
            </div>
            <S.ContactInfo>
              <span style={{ marginLeft: '-10%' }} className="distributorName">
                {infraction.empresa?.nome}
              </span>
              <p style={{ fontSize: '13px', marginLeft: '-10%' }}>
                Filial:{' '}
                {infraction?.motorista?.filial?.nome
                  ? infraction?.motorista.filial?.nome
                  : infraction?.caminhao?.filial?.nome
                  ? infraction?.caminhao.filial?.nome
                  : '-'}
              </p>
            </S.ContactInfo>

            <Divider orientation="vertical" flexItem border="1px" />

            <S.ContactInfo>
              <div style={{ display: 'flex' }}>
                <h1 style={{ fontSize: '16px' }}>Contato: </h1>
                <p style={{ marginLeft: '2%' }}>
                  {infraction.empresa?.nome_contato
                    ? infraction.empresa.nome_contato
                    : 'Não informado'}
                </p>
              </div>
              <div style={{ display: 'flex' }}>
                <h1 style={{ fontSize: '16px' }}>Celular: </h1>
                <p style={{ marginLeft: '2%' }}>
                  {infraction.empresa?.telefone_contato
                    ? infraction.empresa.telefone_contato
                    : 'Não informado'}
                </p>
              </div>
              <div style={{ display: 'flex' }}>
                <h1 style={{ fontSize: '16px' }}>E-mail: </h1>
                <p>
                  {infraction.empresa?.email_contato
                    ? infraction.empresa.email_contato
                    : 'Não informado'}
                </p>
              </div>
            </S.ContactInfo>
          </Grid>
        )}

        <Grid item xs={8} sm={8}>
          {isProvider && !hasTorrePlus && infraction.empresa ? (
            <TextInput
              id="responsavel"
              label="Responsável"
              value={infraction.responsavel?.nome}
              onChange={e => {}}
              placeholder="Responsável não definido"
              disabled
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          ) : (
            <div style={{ marginTop: 2 }}>
              <SelectMultiple
                grupos={grupos}
                width="100%"
                data={responsaveis}
                label={
                  <span style={{ color: 'rgb(101, 110, 140)' }}>
                    Atribuir Responsável
                  </span>
                }
                limitRenderValue={4}
                value={formData.ids_responsaveis}
                disabled={infraction.status === 'DELETADO' || userLevel > 3}
                handleChange={val => handleChange('ids_responsaveis', val)}
                required
              />
            </div>
          )}
        </Grid>

        <Grid item xs={4} sm={4}>
          <Calendar
            disabled={
              infraction.status === 'DELETADO' ||
              (isProvider && !(isOpLogistico || hasTorrePlus)) ||
              (user.nivel === 4 && user?.id !== infraction?.id_responsavel)
            }
            id="data_previsao"
            value={formData.data_previsao}
            onChange={newDate => handleChange('data_previsao', newDate)}
            futureDate
            pastDate={false}
            label="Previsão de Conclusão"
            required
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextInput
            required
            disabled={
              infraction.status === 'DELETADO' ||
              (isProvider && !(isOpLogistico || hasTorrePlus)) ||
              (user.nivel === 4 && user?.id !== infraction?.id_responsavel)
            }
            id="plano_acao"
            placeholder="Definir Plano de Ação"
            label="Plano de Ação"
            onChange={e => handleChange('plano_acao', e.target.value)}
            value={formData.plano_acao || infraction.desvio_info?.plano_acao}
            inputProps={{ maxLength: 1023 }}
            multiline
            rows={4}
            variant={
              infraction.status === 'DELETADO' ||
              (isProvider && !(isOpLogistico || hasTorrePlus))
                ? 'filled'
                : 'outlined'
            }
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <InputAttachedFile
            disabled={
              infraction.status === 'DELETADO' ||
              (isProvider && !(isOpLogistico || hasTorrePlus)) ||
              (user.nivel === 4 && user?.id !== infraction?.id_responsavel)
            }
            idInput="input_file_detalhe"
            label="Anexar Arquivo"
            inputLabel="Anexar Arquivo"
            fileUrl={
              formData.arquivo_plano_acao ||
              infraction.desvio_info?.arquivo_plano_acao
            }
            setFileUrl={value => handleChange('arquivo_plano_acao', value)}
            fileDir={`/desvios/${infraction.id}`}
          />
        </Grid>

        {(infraction.status === 'DELETADO' ||
          infraction.aprovado_distribuidora !== null) && (
          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              disabled
              idInput="input_file_exclusao"
              label="Arquivo Justificativa Exclusão"
              inputLabel="Anexar Evidência"
              fileUrl={
                formData.arquivo_exclusao ||
                infraction.desvio_info?.arquivo_exclusao
              }
              setFileUrl={value => handleChange('arquivo_exclusao', value)}
              fileDir={`/desvios/${infraction.id}`}
            />
          </Grid>
        )}

        {(infraction.status === 'DELETADO' ||
          infraction.aprovado_distribuidora !== null) &&
          infraction?.desvio_info?.observacao_exclusao && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="observacaoexclusao"
                label="Observação da exclusão (transportadora)"
                value={infraction?.desvio_info?.observacao_exclusao || ''}
                inputProps={{ readOnly: true }}
                variant="filled"
                multiline
              />
            </Grid>
          )}

        {(infraction.status === 'DELETADO' ||
          infraction.aprovado_distribuidora !== null) &&
          infraction?.desvio_info?.observacao_distribuidora && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="observacaorevisaodistribuidora"
                label="Observação da embarcadora"
                value={infraction?.desvio_info?.observacao_distribuidora || ''}
                inputProps={{ readOnly: true }}
                variant="filled"
                multiline
              />
            </Grid>
          )}

        {infraction?.desvio_tipo?.desvio_tipo_padrao?.sigla &&
          ['ICO', 'OSC'].includes(
            infraction.desvio_tipo.desvio_tipo_padrao.sigla,
          ) && (
            <Grid item xs={12} sm={12}>
              <SelectInput
                disabled={
                  infraction.status === 'DELETADO' ||
                  (isProvider && !(isOpLogistico || hasTorrePlus)) ||
                  (user.nivel === 4 && user?.id !== infraction?.id_responsavel)
                }
                id="select_objeto"
                label="Objeto"
                value={selectObjeto}
                onChange={e => {
                  setSelectObjeto(e.target.value);
                  handleChange(
                    'objeto',
                    e.target.value === 'OUTRO' ? '' : e.target.value,
                  );
                }}
                placeholder="Selecionar objeto"
                clearLabel="Limpar"
                data={objetos}
              />
            </Grid>
          )}

        {selectObjeto === 'OUTRO' && (
          <Grid item xs={12} sm={12}>
            <TextInput
              disabled={
                infraction.status === 'DELETADO' ||
                (isProvider && !(isOpLogistico || hasTorrePlus)) ||
                (user.nivel === 4 && user?.id !== infraction?.id_responsavel)
              }
              id="objeto"
              placeholder="Insira o objeto"
              label="Outros objeto"
              onChange={e => handleChange('objeto', e.target.value)}
              value={formData.objeto}
            />
          </Grid>
        )}

        {tipoDesvio === 'FADIGA' && (
          <Grid item xs={12} sm={12}>
            <SelectInput
              disabled={
                infraction.status === 'DELETADO' ||
                (isProvider && !(isOpLogistico || hasTorrePlus)) ||
                (user.nivel === 4 && user?.id !== infraction?.id_responsavel)
              }
              id="select_fator_risco"
              label="Fator de Risco"
              value={selectFator}
              onChange={e => {
                setSelectFator(e.target.value);
                handleChange(
                  'fator_risco',
                  e.target.value === 'Outros' ? '' : e.target.value,
                );
              }}
              placeholder="Selecione o fator de risco"
              clearLabel="Limpar"
              data={[
                { value: 'Jornada excedida', name: 'Jornada excedida' },
                {
                  value: 'Descanso não cumprido',
                  name: 'Descanso não cumprido',
                },
                {
                  value: 'Outros',
                  name: 'Outros',
                },
              ]}
            />
          </Grid>
        )}

        {selectFator === 'Outros' && (
          <Grid item xs={12} sm={12}>
            <TextInput
              disabled={
                infraction.status === 'DELETADO' ||
                (isProvider && !(isOpLogistico || hasTorrePlus)) ||
                (user.nivel === 4 && user?.id !== infraction?.id_responsavel)
              }
              id="fator_risco"
              placeholder="Insira o Fator de Risco"
              label="Outros fatores"
              onChange={e => handleChange('fator_risco', e.target.value)}
              value={formData.fator_risco}
            />
          </Grid>
        )}

        {!isProvider && infraction.em_analise === 'RETORNO' && (
          <Grid item xs={12} sm={12}>
            <TextInput
              disabled
              id="observacao_analise"
              placeholder=""
              label="Observação de retorno da análise"
              InputProps={{
                readOnly: true,
              }}
              value={infraction.desvio_info?.observacao_analise}
            />
          </Grid>
        )}

        {(!isProvider || isOpLogistico || hasTorrePlus) &&
          (userLevel < 4 ||
            (userLevel === 4 && infraction.id_responsavel === user.id)) && (
            <Grid
              item
              xs={12}
              sm={12}
              display="flex"
              justifyContent="space-between"
            >
              {(!isProvider || hasTorrePlus) && (
                <div style={{ display: 'flex', gap: '1rem' }}>
                  {/* Exclusão do desvio */}
                  {infraction.status === 'ABERTO' &&
                    userLevel < 3 &&
                    (infraction.aprovado_distribuidora === null ||
                      infraction.aprovado_distribuidora === 3) && (
                      <GhostButton
                        size="medium"
                        customcolor={
                          theme.palette.semantics.feedback.attention.natural
                        }
                        onClick={() => setDesvioDeleteModal({ ...infraction })}
                      >
                        {infraction.aprovado_distribuidora === 3
                          ? 'Alterar evidência de exclusão'
                          : 'EXCLUIR'}
                      </GhostButton>
                    )}

                  {/* Analise interna da transportadora */}
                  {!isProvider &&
                    infraction.status === 'PENDENTE' &&
                    infraction.em_analise !== 'RETORNO' &&
                    (infraction.em_analise !== 'ANALISE' || userLevel > 1) && (
                      <GhostButton
                        disabled={infraction.em_analise === 'ANALISE'}
                        size="medium"
                        onClick={() => handleOpenModal('ANALISAR')}
                        data-test="enviar-analise"
                      >
                        ENVIAR PARA ANALISE
                      </GhostButton>
                    )}
                  {!isProvider &&
                    infraction.status === 'PENDENTE' &&
                    infraction.em_analise === 'ANALISE' &&
                    userLevel < 2 && (
                      <GhostButton
                        size="medium"
                        onClick={() => setDesvioRetornoModal(true)}
                      >
                        RETORNAR PARA ANALISE
                      </GhostButton>
                    )}
                </div>
              )}

              {(!isProvider || isOpLogistico || hasTorrePlus) && (
                <div style={{ display: 'flex', gap: '1rem' }}>
                  {['ABERTO', 'PENDENTE', 'FINALIZADO'].includes(
                    infraction.status,
                  ) && (
                    <GhostButton
                      id="btnSalvar"
                      size="medium"
                      onClick={() => handleOpenModal('SALVAR')}
                    >
                      SALVAR
                    </GhostButton>
                  )}

                  {infraction.status === 'ABERTO' && (
                    <DefaultButton
                      size="medium"
                      onClick={() => {
                        handleOpenModal('APROVAR');
                      }}
                    >
                      APROVAR
                    </DefaultButton>
                  )}

                  {infraction.status === 'PENDENTE' && (
                    <DefaultButton
                      size="medium"
                      onClick={() => {
                        handleOpenModal('FINALIZAR');
                      }}
                    >
                      FINALIZAR
                    </DefaultButton>
                  )}
                </div>
              )}
            </Grid>
          )}

        {isProvider &&
          (infraction.aprovado_distribuidora === 0 ||
            infraction.aprovado_distribuidora === 3) && (
            <Grid item xs={12} sm={12} mt={1}>
              <DefaultButton
                children="Aprovar Exclusão"
                onClick={() =>
                  setOpenRevisaoExclusao({
                    title: 'Tem certeza que deseja aprovar?',
                    subtitle: 'O desvio será deletado',
                    event: 'aprovar',
                  })
                }
              />
              <DefaultButton
                children="Recusar Exclusão"
                style={{
                  backgroundColor:
                    theme.palette.semantics.feedback.attention.natural,
                  margin: '0px 10px 0px 10px',
                }}
                onClick={() =>
                  setOpenRevisaoExclusao({
                    title: 'Tem certeza que deseja rejeitar?',
                    subtitle: 'Desvios recusados são movidos para pendentes',
                    event: 'recusar',
                  })
                }
              />
              {infraction.aprovado_distribuidora === 0 && (
                <>
                  <DefaultButton
                    children="Solicitar Evidências"
                    style={{
                      backgroundColor:
                        theme.palette.semantics.feedback.warning.natural,
                    }}
                    onClick={() =>
                      setOpenRevisaoExclusao({
                        title: 'Tem certeza que deseja solicitar evidências?',
                        subtitle:
                          'A empresa responsável deverá adicionar mais informações',
                        event: 'solicitar-evidencias',
                      })
                    }
                  />
                </>
              )}
            </Grid>
          )}
      </Grid>
    </S.ColumnWrapper>
  );
};

export default Config;
