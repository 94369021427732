import api from 'services/api';
import { brStates } from 'utils/brazilianStates';

export const getResponsaveis = async () => {
  const res = await api.get(`/select-responsibles`);
  const data = res?.data?.rows || [];
  return data.map(i => ({ value: i.id, label: i.value }));
};

export const getCargas = async () => {
  const res = await api.get(`/select-cargas`);
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i.id, label: i.nome }));
};

export const getStatus = async () => {
  return [
    { label: 'Ativo', value: 'Ativo' },
    { label: 'Agendado', value: 'Agendado' },
    { label: 'Finalizado', value: 'Finalizado' },
    { label: 'Rascunho', value: 'Rascunho' },
    { label: 'Inativo', value: 'Inativo' },
  ];
};

export const getStatusItem = async () => {
  return [
    { label: 'Ativo', value: 'ATIVO' },
    { label: 'Inativo', value: 'INATIVO' },
  ];
};

export const getStatusTarefas = async () => {
  return [
    { label: 'Pendente', value: 'PENDENTE' },
    { label: 'Vencido', value: 'VENCIDO' },
    { label: 'Concluído', value: 'CONCLUIDO' },
  ];
};

export const getStatusTelemetria = async () => {
  return [
    { label: 'PENDENTE', value: 'PENDENTE' },
    { label: 'LIBERADO', value: 'LIBERADO' },
    { label: 'APROVADO', value: 'APROVADO' },
    { label: 'REPROVADO', value: 'REPROVADO' },
    { label: 'SUSPENSO', value: 'BLOQUEADO' },
  ];
};

export const getStatusVagas = async () => {
  const res = await api.get('/vacancies-filter');
  const data = res?.data?.status || [];
  return data.map(i => ({ value: i?.value, label: i?.name }));
};

export const getTurmas = async () => {
  return [
    { label: 'Motoristas', value: 'MOTORISTAS' },
    { label: 'Gestores', value: 'GESTORES' },
  ];
};

export const getTiposDesvio = async () => {
  const res = await api.get('/select-infractionTypes');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i.id, label: i.descricao }));
};

export const getTiposDescarga = async () => {
  const res = await api.get('/select-unloadingInfractionTypes');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i.id, label: i.descricao }));
};

export const getCriticidade = async () => {
  return [
    { value: 'PRIMÁRIA', label: 'PRIMÁRIA' },
    { value: 'MODERADA', label: 'MODERADA' },
    { value: 'GRAVE', label: 'GRAVE' },
    { value: 'GRAVÍSSIMA', label: 'GRAVÍSSIMA' },
  ];
};

export const getPropriedade = async () => {
  return [
    { label: 'Próprio', value: 0 },
    { label: 'Agregado', value: 1 },
  ];
};

export const getAgregados = async () => {
  return [
    { label: 'Agregados', value: 'SIM' },
    { label: 'Próprios', value: 'NÃO' },
  ];
};

export const getDepartamento = async () => {
  return [
    { label: 'Coleta', value: 'COLETA' },
    { label: 'Entrega', value: 'ENTREGA' },
    { label: 'Ambos', value: 'AMBOS' },
  ];
};

export const getExecutores = async () => {
  const res = await api.get('/select-executores');
  const data = res?.data?.rows || [];
  return data.map(i => ({ value: i?.id, label: i?.value }));
};

export const getAprovadores = async () => {
  const res = await api.get('/select-aprovadores');
  const data = res?.data?.rows || [];
  return data.map(i => ({ value: i?.id, label: i?.value }));
};

export const getBases = async () => {
  const res = await api.get('/select-bases');
  const data = res?.data?.rows || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};

export const getTamanhos = async () => {
  const res = await api.get('/select-tamanhos');
  const data = res?.data?.rows || [];
  return data.map(i => ({ value: i?.id, label: i?.name }));
};

export const getMotoristas = async () => {
  return [
    { label: 'Agregados', value: 'AGREGADOS' },
    { label: 'Próprios', value: 'PROPRIOS' },
    { label: 'Não Informado', value: '-1' },
  ];
};

export const getVeiculos = async (validos = false) => {
  const res = await api.get('/telematics_tipos_veiculos');
  let data = res?.data?.data || [];
  if (validos) data = data.filter(veiculo => veiculo.id >= 0);
  return data.map(i => ({ id: i.id, value: i.id, label: i.tipo }));
};

export const getResponsaveisPad = async () => {
  const res = await api.get('/select-responsibles-ocorrencias');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};

export const getFilials = async selects => {
  return (
    selects?.filials?.map(s => ({
      ...s,
      label: s.nome,
      value: s.id,
    })) ?? []
  );
};

export const getClients = async () => {
  const res = await api.get('/select-clients');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};

export const getEmpresas = async () => {
  const res = await api.get(`/select-empresas-raizen`);
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i.id, label: i.nome }));
};

export const getEmpresasLayout = async () => {
  const res = await api.get(`/select-empresas-layout`);
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i.id, label: i.nome }));
};

export const getOperations = async () => {
  return [
    { label: 'Primária', value: 1 },
    { label: 'Secundária', value: 2 },
    { label: 'Açucar', value: 3 },
  ];
};

export const getFaixas = async () => {
  const res = await api.get('/telematics_acoes');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.titulo }));
};

export const getAcao = async () => {
  const res = await api.get('/telematics_acoes_disciplinares');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};

export const getDesviosTelemetria = async () => {
  const res = await api.get('/telematics_violacao_padrao', {
    params: { categoria: ['DESVIO', 'CERCA', 'VELOCIDADE', 'JORNADA'] },
  });
  const data = res?.data?.data;
  return data.map(i => ({ value: i?.id, label: i?.tipo }));
};

export const getDesviosFadiga = async () => {
  const res = await api.get('/telematics_violacao_padrao', {
    params: { categoria: ['FADIGA'] },
  });
  const data = res?.data?.data;
  return data.map(i => ({ value: i?.id, label: i?.tipo }));
};

export const getCondicoes = async () => {
  return [
    { label: 'Pista Molhada', value: 'true' },
    { label: 'Pista Seca', value: 'false' },
    { label: 'Não Informado', value: '-1' },
  ];
};

export const getCarregamentos = async () => {
  return [
    { label: 'Carregado', value: 'true' },
    { label: 'Vazio', value: 'false' },
    { label: 'Não Informado', value: '-1' },
  ];
};

export const getResponsaveisVagas = async () => {
  const res = await api.get('/vacancies-filter');
  const data = res.data.responsaveis || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};

export const getCidade = async () => {
  const res = await api.get('/vacancies-filter');
  const data = res?.data?.cidades || [];
  return data.map(i => ({ value: i?.value, label: i?.name }));
};

export const getTipoViolacoes = async () => {
  const res = await api.get('/select-violationTypes');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.descricao }));
};

export const getDesviosTiposPadrao = async () => {
  const res = await api.get('/desvios/configuracoes/padroes');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};

export const getDesviosTipos = async () => {
  const res = await api.get('/desvios/configuracoes/tipos');
  const data = res?.data?.data || [];
  return data.map(i => ({
    value: i?.id,
    label: i?.nome,
    id_desvio_tipo_padrao: i?.id_desvio_tipo_padrao,
  }));
};

export const getTecnologias = async () => {
  const res = await api.get('/tecnologias');
  const data = res?.data?.data || [];
  return data.map(i => ({
    value: i?.id,
    label: i?.nome,
    ids_tipos: i?.desvio_tipos_padroes?.map(t => t.desvio_tipo_padrao?.id),
  }));
};

export const getStates = () => {
  return brStates.map(i => ({ value: i.value, label: i.name }));
};

export const getTypeClientIp = () => {
  return [
    { label: 'IPIRANGA EMPRESAS', value: 'EMPRESA' },
    {
      label: 'POSTO REVENDEDOR',
      value: 'REVENDEDOR',
    },
  ];
};

export const getStatusAcaoSuspensao = async () => {
  return [
    { label: 'PENDENTE', value: 'PENDENTE' },
    { label: 'LIBERADO', value: 'LIBERADO' },
    { label: 'APROVADO', value: 'APROVADO' },
    { label: 'REPROVADO', value: 'REPROVADO' },
    { label: 'SUSPENSO', value: 'BLOQUEADO' },
  ];
};

export const getAcaoDisciplinar = async () => {
  const res = await api.get('/acoes-disciplinares');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};

export const getFaixasAcoes = async params => {
  const res = await api.get('/acoes-suspensoes/faixas', {
    params: { ativo: 1, ...params },
  });
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.titulo }));
};
