import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import ConfirmModal from 'components/ConfirmModal';
import EmptyCard from 'components/Cards/EmptyDataCard';

// MATERIAL UI
import { MenuItem, Popover } from '@mui/material';
import { Check, MoreHoriz } from '@mui/icons-material';

// UTILS
import { useTheme } from 'styled-components';
import { formatNewDate } from 'utils/dates';
import * as services from '../services';
import * as S from './styled';

// MODAL (Imported From Desvios Page)
import DeleteModal from '../../../Desvios/DeleteModal';

export const Desvios = ({ evaluation, setRecharge }) => {
  const theme = useTheme();
  const desvios = evaluation.desvios_motoristas;
  const navigate = useNavigate();

  // Redux
  const selects = useSelector(state => {
    return state.selects;
  });
  const user = useSelector(state => state.auth?.user?.user);
  const userLevel = user.nivel;

  const listaResponsaveis =
    selects.users?.map(item => {
      return { value: item.id, name: item.nome };
    }) || [];

  // Modal Controll
  const [finishModal, setFinishModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [desvio, setDesvio] = useState(null);

  const setStatusColor = status => {
    switch (status) {
      case 'ABERTO':
        return theme.palette.semantics.feedback.attention.natural;
      case 'ANALISE':
        return theme.palette.semantics.feedback.unknown.natural;
      case 'PENDENTE':
        return theme.palette.semantics.feedback.warning.natural;
      case 'FINALIZADO':
        return theme.palette.semantics.feedback.success.natural;
      case 'DELETADO':
        return theme.palette.semantics.feedback.unknown.light;
    }
  };

  const setTypeColor = type => {
    switch (type) {
      case 'PRIMÁRIA':
        return {
          color: theme.palette.semantics.feedback.information.natural,
          background: theme.palette.semantics.feedback.information.light,
        };
      case 'MODERADA':
        return {
          color: theme.palette.semantics.feedback.warning.natural,
          background: theme.palette.semantics.feedback.warning.light,
        };
      case 'GRAVE':
        return {
          color: theme.palette.semantics.feedback.attention.natural,
          background: theme.palette.semantics.feedback.attention.light,
        };
      case 'GRAVÍSSIMA':
        return {
          color: theme.palette.semantics.feedback.attention.natural,
          background: theme.palette.semantics.feedback.attention.light,
        };
      default:
        return {};
    }
  };

  // ********************  ACTIONS *****************************
  // Modais

  const handleModalFinalizar = desv => {
    setFinishModal(true);
  };

  const handleDeleteModal = desv => {
    setDeleteModal(true);
  };

  // Funções
  const handleFinalizar = async () => {
    setLoadingModal(true);

    const data = { ids: [desvio.id] };
    const res = await services.finishInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      setRecharge(true);
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
  };

  const handleDeletar = async data => {
    setLoadingModal(true);
    const res = await services.deleteInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      setRecharge(true);
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
  };

  const handleOpenNewTab = infrac => {
    return window.open(`/desvios/${infrac.id}`);
  };

  // CONTROLE DE EXIBIÇÃO DAS AÇÕES
  const [anchor, setAnchor] = useState(null);
  const [actions, setActions] = useState([]);
  const allActions = [
    {
      name: 'abrir',
      action: handleOpenNewTab,
      text: 'Abrir em nova guia',
    },
    {
      name: 'finalizar',
      action: handleModalFinalizar,
      text: 'Finalizar',
    },
    {
      name: 'deletar',
      action: handleDeleteModal,
      text: 'Excluir',
    },
  ];
  const setActionsPopover = (e, desvioEl) => {
    setAnchor(e.currentTarget);
    setDesvio(desvioEl);
    let currentActions = [];
    switch (desvioEl.status) {
      case 'ABERTO':
        if (desvioEl.aprovado_distribuidora !== null) {
          currentActions = ['visto', 'abrir', 'aprovar', 'imprimir'];
        } else {
          currentActions = ['visto', 'abrir', 'aprovar', 'imprimir', 'deletar'];
        }
        break;
      case 'ANALISE':
        break;
      case 'PENDENTE':
        currentActions = ['visto', 'abrir', 'imprimir', 'finalizar'];
        break;
      case 'FINALIZADO':
        currentActions = ['abrir', 'imprimir'];
        break;
      case 'DELETADO':
        break;
    }
    if (userLevel > 3) currentActions = ['visto', 'abrir'];

    const ac = [];
    for (const i in allActions) {
      if (currentActions.includes(allActions[i].name)) ac.push(allActions[i]);
    }
    setActions(ac);
  };

  return (
    <div>
      {desvios &&
        desvios.map((desvMap, idx) => (
          <S.DesvioBox key={idx} color={setStatusColor(desvMap.status)}>
            <div className="imgArea">
              <img src={desvMap.desvio_info?.imagem} />
            </div>
            <div className="contentArea">
              <div className="top">
                <span className="texts">
                  <span className="date">
                    {formatNewDate(desvMap.data_desvio)}
                  </span>
                  <span className="id">#{desvMap.id}</span>
                </span>
                <span className="actionsSide">
                  <span onClick={() => navigate(`/desvios/${desvMap.id}`)}>
                    Visualizar Desvio
                  </span>
                  <MoreHoriz
                    fontSize="large"
                    onClick={e => setActionsPopover(e, desvMap)}
                  />
                  <Popover
                    id={
                      anchor && desvMap?.id === desvio?.id
                        ? 'popover'
                        : undefined
                    }
                    open={Boolean(anchor && desvMap?.id === desvio?.id)}
                    anchorEl={
                      anchor && desvMap?.id === desvio?.id ? anchor : null
                    }
                    onClose={() => setAnchor(null)}
                    onClick={() => setAnchor(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    {actions.map(action => {
                      return (
                        <MenuItem
                          key={action.name}
                          onClick={() =>
                            action.action && action.action(desvMap)
                          }
                        >
                          <S.ItemPopover>{action.text}</S.ItemPopover>
                        </MenuItem>
                      );
                    })}
                  </Popover>
                </span>
              </div>
              <div className="medium">
                <span className="dsTitle">{`Desvio: ${desvMap.desvio_tipo.titulo}`}</span>
                <span className="name">
                  {evaluation.motorista.nome.toLowerCase()}
                </span>
              </div>
              <div className="bottom">
                <span className="status">
                  <span className="ball" />
                  <span className="text">{desvMap.status.toLowerCase()}</span>
                </span>
                <span className="type">
                  Criticidade{' '}
                  <span style={setTypeColor(desvMap.criticidade)}>
                    {desvMap.criticidade}
                  </span>
                </span>
              </div>
            </div>
          </S.DesvioBox>
        ))}
      {desvios.length <= 0 && (
        <EmptyCard
          image="frota.png"
          title="Nenhum desvio na avaliação"
          subtitle="Nada para exibir"
        />
      )}

      {/* Modal Area */}
      {/* {approveModal && (
        <ApproveModal
          title={"Deseja aprovar o desvio selecionado?"}
          subtitle={"O desvio será movido para aba Pendentes."}
          handleConfirm={handleAprovar}
          ids={[desvio.id]}
          handleClose={() => setApproveModal(false)}
          responsaveis={listaResponsaveis}
          loading={loadingModal}
        />
      )} */}

      {finishModal && (
        <ConfirmModal
          handleClose={() => setFinishModal(false)}
          open={finishModal}
          title="Deseja finalizar o desvio selecionados?"
          titleIcon={
            <Check
              sx={{ color: theme.palette.brand.secondary.natural }}
              fontSize="medium"
            />
          }
          subtitle="O desvio será alterado para Finalizado."
          buttonText="Confirmar"
          onClick={() => handleFinalizar()}
          loading={loadingModal}
        />
      )}

      {deleteModal && (
        <DeleteModal
          handleConfirm={handleDeletar}
          desvio={desvio}
          handleClose={() => setDeleteModal(false)}
          loading={loadingModal}
        />
      )}
    </div>
  );
};
