export const padWithLeadingZeros = (num, totalLength) => {
  if (num === 0) return num;
  return String(num).padStart(totalLength, '0');
};

export const objectGet = (obj, path, def = null) => {
  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  let stringToPath = function (path) {
    // If the path isn't a string, return it
    if (typeof path !== 'string') return path;

    // Create new array
    let output = [];

    // Split to an array with dot notation
    path.split('.').forEach(function (item, index) {
      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {
        // Push to the new array
        if (key.length > 0) {
          output.push(key);
        }
      });
    });

    return output;
  };

  // Get the path as an array
  path = stringToPath(path);

  // Cache the current object
  let current = obj;

  // For each item in the path, dig into the object
  for (let i = 0; i < path.length; i++) {
    // If the item isn't found, return the default (or null)
    if (!current[path[i]]) return def;

    // Otherwise, update the current  value
    current = current[path[i]];
  }

  return current;
};
export function formatarTempo(duration) {
  const segundos = Math.floor((duration / 1000) % 60);
  const minutos = Math.floor((duration / 1000 / 60) % 60);
  const horas = Math.floor(duration / 1000 / 60 / 60);

  const formatado = `${horas.toString().padStart(2, '0')}:${minutos
    .toString()
    .padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;

  return formatado;
}

export function validateCPF(cpf) {
  // Removendo caracteres especiais
  cpf = cpf.replace(/[^\d]+/g, '');
  // Verificando se o CPF tem 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Verificando se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  // Calculando o primeiro dígito verificador
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let resto = (soma * 10) % 11;
  let digitoVerificador1 = resto === 10 ? 0 : resto;

  // Calculando o segundo dígito verificador
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  let digitoVerificador2 = resto === 10 ? 0 : resto;

  // Verificando se os dígitos verificadores são válidos
  if (
    digitoVerificador1 === parseInt(cpf.charAt(9)) &&
    digitoVerificador2 === parseInt(cpf.charAt(10))
  ) {
    return true;
  }
  return false;
}

export const downloadFromLink = url => {
  const link = document.createElement('a');
  link.href = url;
  link.download = '';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const Criticidades = {
  PRIMARIA: '#1BC5BD',
  PRIMÁRIA: '#1BC5BD',
  PRIMARIO: '#1BC5BD',
  GRAVISSIMO: '#F64E60',
  GRAVÍSSIMO: '#F64E60',
  GRAVÍSSIMA: '#F64E60',
  MODERADO: '#0C12F2',
  MODERADA: '#0C12F2',
  GRAVE: '#FFA801',
};

const getCriticidadeColor = (status, theme) => {
  switch (status) {
    case 'PRIMÁRIA':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'MODERADA':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'GRAVE':
    case 'GRAVÍSSIMA':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    default:
      theme?.palette?.semantics?.feedback?.unknown?.natural;
  }
};
const hexToRgba = (hex, opacity) => {
  if (!hex) return 'rgba(0, 0, 0, 0)';

  hex = hex.replace(/^#/, '');
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const criticidade = criticidade => {
  if (!criticidade) return '-';
  return (
    <div
      style={{
        color: Criticidades[criticidade],
        backgroundColor: `${hexToRgba(Criticidades[criticidade], 0.1)}`,
        borderRadius: 20,
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      {criticidade}
    </div>
  );
};

export const getCriticidade = criticidade => {
  switch (criticidade) {
    case 'ALTISSIMO':
      return '#f64e60';
    case 'ALTO':
      return '#FFA801';
    case 'GRAVÍSSIMA':
      return `#F64E60`;
    case 'GRAVE':
    case 'ALTO':
      return `#FFA801`;
    case 'MODERADA':
    case 'PERIGO':
      return `#0C12F2`;
    case 'BAIXO':
      return `#1BC5BD`;
    default:
      return `#95AAC9`;
  }
};

export const simplifyNumber = num => {
  if (num >= 1_000_000_000_000) {
    // Trilhão
    return `${(num / 1_000_000_000_000).toFixed(1)}T`;
  }
  if (num >= 1_000_000_000) {
    // Bilhão
    return `${(num / 1_000_000_000).toFixed(1)}B`;
  }
  if (num >= 1_000_000) {
    // Milhão
    return `${(num / 1_000_000).toFixed(1)}M`;
  }
  if (num >= 1_000) {
    // Milhar
    return `${(num / 1_000).toFixed(1)}k`;
  }
  // Valores menores que mil
  return num.toString();
};
