import styled from 'styled-components';

export const StaticRangeButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 4px;
  width: 214px;
  height: 32px;
  background: ${({ selected }) => (selected ? '#4b5166' : '#edf2f9')};
  border-radius: 100px;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    border: 1px solid #4b5166;
  }

  span {
    font-family: 'Texta';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: ${({ selected }) => (selected ? '#fff' : '#4b5166')};
    flex: none;
    order: 1;
    flex-grow: 0;
  }
`;
