import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  Tooltip,
  LabelList,
} from 'recharts';
import { startOfMonth, subMonths } from 'date-fns';

// styles
import * as S from './styled';
import { ptMonths } from 'utils/dates';

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#95AAC9"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

export const GraficoDesviosTratadoNoprazo = ({
  data = [],
  handleDate,
  metaValue = 10,
  title = '',
  filter = null,
  filterCustom,
}) => {
  const [finalDate, setFinalDate] = useState(new Date());
  const [initialDate, setIntitalDate] = useState(
    startOfMonth(subMonths(new Date(), 11)),
  );

  // Converte mes e ano. Ex: 05/2023 >> maio-2023
  const dataRender = data?.slice(-12).map(item => {
    return {
      name: item.ano,
      x: Number(item.x),
      meta: item.meta,
    };
  });

  const maxValue = dataRender?.reduce(
    (max, item) => (item.x > max ? item.x : max),
    dataRender[0]?.x || 0,
  );

  const minValue = dataRender?.reduce(
    (min, item) => (item.x < min ? item.x : min),
    dataRender[0]?.x || 0,
  );

  return (
    <S.Container>
      <div style={{ display: 'flow', width: '100%', marginBottom: 20 }}>
        {filterCustom}
      </div>
      <ResponsiveContainer width="100%" height="60%">
        <LineChart
          height={200}
          data={dataRender}
          margin={{ top: 15, right: 40, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            height={70}
            tick={<CustomTick />}
            interval={0}
          />
          <YAxis domain={[minValue, maxValue]} />

          <Line
            type="linear"
            dataKey="x"
            stroke="#0C12F2"
            dot={{ r: 0 }}
            activeDot={{ r: 0 }}
          >
            <LabelList dataKey="x" position="top" fill="#000" />
          </Line>
          <Tooltip formatter={(value, name, props) => [value, `Tratativas:`]} />
          <ReferenceLine
            y={metaValue}
            label={{
              value: `Meta ${metaValue}`,
              position: 'insideBottomRight',
              fill: 'red',
              style: {
                fontSize: 12,
                fontWeight: 'bold',
              },
            }}
            stroke="red"
            strokeDasharray="3 3"
          />
        </LineChart>
      </ResponsiveContainer>
    </S.Container>
  );
};
