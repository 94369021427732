import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-width: 100%;
  padding-left: 50px;
  flex-direction: column;

  .select-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 16px 0;
    .title-header {
      font: normal normal bold 16px/20px Texta;
      letter-spacing: 0px;
      color: #4b5166;
      white-space: nowrap;
    }
  }
  .label-hilight {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    -ms-user-select: none;
    user-select: none;
    font: normal normal medium 14px/20px Texta;
    letter-spacing: 0px;
    color: #ffffff;
    background: #2e3240;
    border-radius: 12px;
  }
  .label-hilight > span {
    margin-left: 8px;
    font-weight: bold;
  }
  .label-hilight > span:hover {
    cursor: pointer;
    opacity: 0.2;
    border-radius: 4px;
  }
  .label-hilight-prazo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
    letter-spacing: 0px;
    color: #ffffff;
    background: #bdcc30;
    border-radius: 12px;
  }
  .input-title {
    border: none;
    background: transparent;
    font-size: 32px;
    color: #4b5166;
    width: 70%;
    opacity: 0.4;
  }
  .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
  }
  .title {
    font-size: 24px;
    font-weight: 900;
    color: #4b5166;
  }
  .info {
    font-size: 14px;
    font-weight: 900;
    color: #0c12f2;
  }
  .new-list {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: row;

    span {
      white-space: nowrap;
      color: ${({ theme }) =>
        theme.palette.semantics.feedback.attention.natural};
    }
  }

  .container-step {
    display: flex;
    align-items: start;
    padding: 0 20px;
    margin-top: 20px;
  }
  .label-step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 254px;
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fff;
    padding-bottom: 10px;
    padding-top: 10px;
    border: 1px solid #95aac94d;
    border-radius: 4px;
    text-align: center;
    font: normal normal medium 16px/24px Texta;
    letter-spacing: 0px;
    color: #4b5166;
    margin-right: 5px;
  }
  .label-prazo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    text-align: center;
    color: #4b5166;
    margin-bottom: 16px;
  }
  .label-switch {
    display: flex;
    align-items: center;
    width: 330px;
    height: 50px;
    color: #4b5166;
    margin-bottom: 16px;
  }
  .btn-config-push {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4b5166;
    border-radius: 4px;
    gap: 8px;
    width: 141px;
    height: 50px;
    margin-left: 5px;
    font: normal normal normal 16px/24px Texta;
    letter-spacing: 0px;
    text-align: center;
    color: #ffffff;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.5;
    cursor: pointer;
  }
  .btn-config-push:hover {
    opacity: 0.2;
    transition: opacity ease-in 0.5s;
  }
  .active {
    opacity: 1;
  }
  .select-time {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    span {
      margin-left: 1rem;
      white-space: nowrap;
      color: ${({ theme }) =>
        theme.palette.semantics.feedback.attention.natural};
    }
  }
  .container-btn-time {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .btn-time {
    width: 50px;
    margin: 2px;
    height: 23px;
    background: #fff;
    border: 1px solid #95aac94d;
    border-radius: 4px;
    color: #4b5166;
  }

  .btn-time:hover {
  }

  .btn-time.disabled {
    opacity: 0.3;
  }
  .display-time::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .display-time {
    appearance: textfield;
    -moz-appearance: textfield;
    width: 197px;
    height: 50px;
    border: 1px solid #95aac94d;
    border-radius: 4px;
    text-align: center;
    text-align: center;
    font-size: 32px;
    letter-spacing: 0px;
    color: #4b5166;
    opacity: 0.4;
  }
  .error {
    opacity: 0.8;
    border: 2px solid
      ${({ theme }) => theme.palette.semantics.feedback.attention.natural};
  }
  .container-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-top: 40px;
    .icon {
      background: #edf2f9;
      border-radius: 50%;
      width: 33px;
      height: 33px;
    }
    .btn-add {
      background: #edf2f9;
      border: 1px solid #95aac94d;
      border-radius: 4px;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-left: 20px;
      color: #4b5166;
      font-size: 16px;
      cursor: pointer;
      user-select: none;
    }
    .btn-add:hover {
      opacity: 0.8;
    }
  }
`;
