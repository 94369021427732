import React, { useCallback, useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import cep from 'cep-promise';
import { Grid } from '@mui/material';
import { Container } from './styled';
import { GoogleMap } from 'components/GoogleMap';
import {
  MapInfoButton,
  MapInfoContent,
  MapInfoWrapper,
} from 'components/GoogleMap/styled';
import PlacesAutocomplete, {
  getOpenLatLng,
} from 'components/PlacesAutocomplete';
import Input from 'components/Inputs/TextField';
import TextField from 'components/Inputs/_withController/TextField';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import TextInput from 'components/Inputs/TextField';
import axios from 'axios';

export const GetLocation = ({
  control,
  setValue,
  watch,
  errors,
  setError,
  clearErrors,
  originalPos,
}) => {
  // MAP STATES
  const [map, setMap] = useState(null);
  const [position, setPosition] = useState(null);
  const [marker, setMarker] = useState(null);
  const [mapInfo, setMapInfo] = useState({
    open: true,
    content: 'Busque a localização ou navegue no mapa aplicando o zoom',
  });
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const endereco = watch('endereco');

  // Controla a exibição dos marcadores no mapa
  // Atualiza o valor no formulario
  useEffect(() => {
    if (!map) return;

    if (marker?.dragListener)
      window.google.maps.event.removeListener(marker.dragListener);
    if (marker?.ref) marker.ref.setMap(null);

    if (position) {
      const _markerRef = new window.google.maps.Marker({
        map,
        draggable: true,
        position,
      });

      // Add marker dragEndListner
      const markerDragListener = google.maps.event.addListener(
        _markerRef,
        'dragend',
        async function () {
          const position = _markerRef.getPosition();
          setMarker(_marker => {
            const markerRef = _marker.ref;
            markerRef.position = {
              lat: position.lat(),
              lng: position.lng(),
            };
            setValue('latitude', position.lat());
            setValue('longitude', position.lng());
            getAddress(position.lat(), position.lng(), true);
            return { ..._marker, ref: markerRef };
          });
        },
      );

      setMarker({ ref: _markerRef, dragListener: markerDragListener });
    }

    return () => {
      if (marker?.dragListener)
        window.google.maps.event.removeListener(marker.dragListener);
      if (marker?.ref) marker.ref.setMap(null);
    };
  }, [map, position]);

  useEffect(() => {
    let clickListener = null;
    if (map) {
      clickListener = map.addListener(
        'click',
        async e => {
          const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
          setValue('latitude', position.lat);
          setValue('longitude', position.lng);
          setLatitude(position.lat);
          setLongitude(position.lng);
          getAddress(position.lat, position.lng, true);
          setPosition(position);
        },
        { once: true },
      );

      if (originalPos) {
        map.setCenter(originalPos);
        map.setZoom(13);
        setPosition(originalPos);
      }
    }

    return () =>
      clickListener && window.google.maps.event.removeListener(clickListener);
  }, [map]);

  const onBlurCep = useCallback(({ target: { value } }) => {
    if (value) {
      cep(value, { timeout: 5000 })
        .then(res => {
          console.log(res);
          const rua = res.street ? `${res.street} -` : '';
          const bairro = res.neighborhood ? `${res.neighborhood},` : '';
          const cidade = res.city ? `${res.city} -` : '';
          const estado = res.state ? `${res.state}` : '';

          const end = `${rua} ${bairro} ${cidade} ${estado}`;

          setValue('endereco', end);
          clearErrors('endereco');

          getOpenLatLng(end).then(({ data }) => {
            const position = {
              lat: Number(data[0].lat),
              lng: Number(data[0].lon),
            };

            onChangeAddress(position, end);
          });
        })
        .catch(() => {
          setValue('endereco', '');
          setError('endereco.cep', {
            type: 'custom',
            message: 'Cep não encontrado.',
          });
        });
    }
  }, []);

  const handleLat = event => {
    setLatitude(event.target.value);
    setValue('latitude', event.target.value);
  };

  const handleLong = event => {
    setLongitude(event.target.value);
    setValue('longitude', event.target.value);
  };

  const setLatLong = event => {
    event.stopPropagation();
    if (event?.key === 'Enter') {
      getAddress(Number(latitude), Number(longitude), true);

      setPosition({
        lat: Number(latitude),
        lng: Number(longitude),
      });
    }
  };

  const onChangeAddress = (position, address) => {
    // console.log(position);
    map?.setCenter(position);
    map?.setZoom(13);
    setValue('endereco', address);
    setValue('latitude', position.lat);
    setValue('longitude', position.lng);
    setLatitude(position.lat);
    setLongitude(position.lng);
    setPosition(position);
    getAddress(position.lat, position.lng);
    setMapInfo(state => ({
      ...state,
      content: 'Arraste o marcador para a posição mais próxima',
    }));
  };

  const getAddress = async (lat, lon, fromMap = false) => {
    try {
      const { data } = await axios.get(
        `https://nominatim.openstreetmap.org/reverse`,
        {
          params: {
            lat,
            lon,
            format: 'json',
          },
        },
      );
      // console.log(data);
      if (data?.address) {
        setValue('cep', data.address.postcode);
        clearErrors('cep');

        if (fromMap) {
          let endereco = '';
          if (data.address.road) endereco += `${data.address.road}, `;
          if (data.address.neighbourhood)
            endereco += `${data.address.neighbourhood}, `;
          if (data.address.hamlet) endereco += `${data.address.hamlet}, `;
          if (data.address.town) endereco += `${data.address.town}, `;
          if (data.address.city) endereco += `${data.address.city}, `;
          if (data.address.state) endereco += `${data.address.state}, `;
          if (data.address.country) endereco += `${data.address.country}`;
          setValue('endereco', endereco);
          clearErrors('endereco');
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <GoogleMap
        mapContainerClassName="map"
        options={{
          center: {
            lat: -13.923025,
            lng: -56.8509357,
          },
          zoom: 4,
          zoomControl: true,
          scrollwheel: false,
        }}
        getMapInstance={mapInstance => setMap(mapInstance)}
      >
        {mapInfo?.open ? (
          <MapInfoWrapper>
            <MapInfoContent>{mapInfo.content}</MapInfoContent>
            <MapInfoButton
              onClick={e => setMapInfo(state => ({ ...state, open: false }))}
            >
              <Close fontSize="8px" />
            </MapInfoButton>
          </MapInfoWrapper>
        ) : (
          ''
        )}
      </GoogleMap>
      <br />
      {map && (
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12} md={6}>
            <PlacesAutocomplete
              required
              label="Rodovia"
              sx={{
                width: '100%',
              }}
              value={endereco}
              onChange={onChangeAddress}
              onInputChange={address => setValue('endereco', address)}
              error={!!errors.endereco}
              message={errors.endereco?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              control={control}
              rules={{
                required: { value: true, message: 'Campo obrigatório.' },
                validate: value =>
                  !isNaN(value) || 'O valor deve ser um número',
              }}
              type="number"
              name="km"
              label="Km"
              placeholder="Preencha o km"
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              rules={{
                required: { value: true, message: 'Campo obrigatório.' },
              }}
              control={control}
              name="cep"
              render={({ field }) => (
                <InputMask mask="99.999-999" {...field} onBlur={onBlurCep}>
                  {() => (
                    <TextInput
                      required
                      error={!!errors.cep?.message}
                      message={errors.cep?.message}
                      label="CEP"
                      placeholder="CEP"
                    />
                  )}
                </InputMask>
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Input
              required
              onKeyDown={setLatLong}
              onChange={handleLat}
              value={latitude}
              name="latitude"
              label="Latitude"
              placeholder=""
              errors={errors}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Input
              required
              onKeyDown={setLatLong}
              onChange={handleLong}
              value={longitude}
              name="longitude"
              label="Longitude"
              placeholder=""
              errors={errors}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};
