// React
import React, { useState, useEffect } from 'react';
import Card from './Card';

// components custom
import { Filter } from 'components/Filters';

// mui ui components
import Skeleton from '@mui/material/Skeleton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Styles
import * as S from './styled';

// services
import { getInfoCards, getFatoresRiscoMonth } from '../services';
import { useSelector } from 'react-redux';
import { usePlans } from 'hooks/usePlans';

const Cards = ({ handleFilters, selecteds }) => {
  const { isProvider } = usePlans();

  const selects = useSelector(state => state.selects);

  const filiais =
    selects?.filials?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const clients =
    selects?.clients?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const empresas =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const filterProps = [
    { name: 'filial', placeholder: 'Filtrar por filial', data: filiais },
    { name: 'client', placeholder: 'Filtrar por cliente', data: clients },
  ];

  const filterPropsProvider = [
    { name: 'empresa', placeholder: 'Filtrar por empresa', data: empresas },
  ];

  const [infoCards, setInfoCards] = useState([1, 2, 3, 4, 5]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFator, setIsLoadingFator] = useState(false);
  const [fatoresRiscoMonth, setFatoresRiscoMonth] = useState(null);

  const fetchInfoCards = async filters => {
    setIsLoading(true);
    const res = await getInfoCards({ ...filters });
    setInfoCards(res.data || []);
    setIsLoading(false);
  };

  const fetchGetFatoresRiscoMonth = async filters => {
    setIsLoadingFator(true);
    const res = await getFatoresRiscoMonth({ ...filters });
    setFatoresRiscoMonth(res.data || {});
    setIsLoadingFator(false);
  };

  useEffect(() => {
    fetchInfoCards(selecteds);
    fetchGetFatoresRiscoMonth(selecteds);
  }, [selecteds]);

  const textoFirstcard = (
    <div>
      no desvio de{' '}
      <span style={{ color: '#BABF1C' }}>
        {fatoresRiscoMonth?.fator_risco || ''}
      </span>{' '}
      no período selecionado
    </div>
  );

  return (
    <S.Container>
      {/* <Filter
        sections={isProvider ? filterPropsProvider : filterProps}
        setFilters={handleFilters}
      /> */}

      {isLoadingFator ? (
        <Skeleton
          style={{ marginTop: '1rem' }}
          height={160}
          animation="wave"
          variant="rounded"
        />
      ) : (
        <Card
          title={true ? 'Houve um aumento de' : 'Houve diminuição de'}
          percentage={`${fatoresRiscoMonth?.porcentagem || 0}`}
          fontSizeTotal={4}
          titleFooter={textoFirstcard}
          selecteds={selecteds}
        />
      )}

      {isLoading
        ? [1, 1, 1, 1, 1].map((i, k) => (
            <Skeleton key={k} height={100} animation="wave" variant="rounded" />
          ))
        : infoCards.map((card, index) => (
            <Card
              key={index}
              title={card.title}
              percentage={
                <span
                  style={{ color: `${card.color ? card.color : '#4b5166'}` }}
                >
                  {card?.value >= 0 || card?.value ? card?.value : '-'}
                </span>
              }
              titleFooter={card.meta ? `Meta` : `Período selecionado`}
              valueFooter={
                card.meta ? (
                  <span style={{ fontWeight: 900 }}>{card.meta}</span>
                ) : (
                  <span
                    style={{
                      display: 'flex',
                      fontWeight: 900,
                      color: `${card.prev_month_color}`,
                      justifyContent: 'center',
                    }}
                  >
                    {card.isUp === true && (
                      <ArrowDropUpIcon
                        sx={{
                          color: card.prev_month_color,
                        }}
                      />
                    )}
                    {card.isUp === false && (
                      <ArrowDropDownIcon
                        sx={{
                          color: card.prev_month_color,
                        }}
                      />
                    )}

                    {card.prev_month}
                  </span>
                )
              }
            />
          ))}
    </S.Container>
  );
};

export default Cards;
