import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Container } from './styled';

// icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// componentes
import { GraphBar } from './components/GraphBar';
import { GraphBarForaDoPrazo } from './components/GraphBarForaDoPrazo';
import SelectList from './components/SelectList';

import PieGraph from './components/PieGraph';
import BarChartVertical from './components/GraficoBarVertical';
import Tabs from 'components/Tabs';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import GraficoBarraVertical from './components/GraficoBarraVertical';
import { GraficoHitorico } from './components/GraficoHistorico';
import { format, parse, isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import DateRangePicker from 'components/DateRangePicker';
import { useNavigate } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';
import { ptBR } from 'date-fns/locale';

import {
  getHistoricoTratativas,
  getJustified,
  getEquipe,
  getMovimentacoes,
  getDesviosTratadosForaPrazo,
} from './services';

import {
  legends_client,
  legends_empresa,
  tabs_client,
  tabs_empresa,
  StyledBadge,
  formatDataGraphPie,
  calculateTotalsAndPercentages,
} from './constants';

const TrativasDesvios = () => {
  const { isProvider, hasTorre, hasTorrePlus } = usePlans();

  const [filterTratativa] = useSelector(state => {
    return [
      isProvider ? state.filterProvider : state.filter,
      state.filterFadigaTratativaDesvios.filter,
    ];
  });
  const selects = useSelector(state => state.selects);

  const navigate = useNavigate();
  const [filterDate, setFilterDate] = useState({
    initialDate: new Date(),
    finalDate: new Date(),
  });

  const [currentTab, setCurrentTab] = useState('CRITICIDADE');
  const [historyGraph, setHistoryGraph] = useState([]);
  const [dateHistory, setDateHistory] = useState(null);
  const [historyFilterType, setHistoryFiterType] = useState('TRATADOSNOPRAZO');
  const [graphJustied, setGraphJustied] = useState(['TRATADOSNOPRAZO']);
  const [graphMovimentacao, setGraphMovimentacao] = useState([]);
  const [graphMoviPie, setGraphMovPie] = useState([]);
  const [desviosTratados, setDesviosTratados] = useState([]);
  const [desviosTratadosforaPrazo, setDesviosTratadosForaPrazo] = useState([]);

  const [loadingCard, setLoadingCard] = useState(false);
  const [loadingMovimentacoes, setLoadingMovimentacoes] = useState(false);
  const [loadingjustify, setLoadingJustify] = useState(false);
  const [loadingDtfp, setLoadingDtfp] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [cardOne, setCardOne] = useState([]);
  const [cardTwo, setCardTwo] = useState([]);

  function splitArray(array) {
    const len = array.length;
    const mid = Math.ceil(len / 2);

    const firstHalf = array.slice(0, mid);
    const secondHalf = array.slice(mid);

    return [firstHalf, secondHalf];
  }

  const shouldDisplay = isProvider ? hasTorrePlus : hasTorre || hasTorrePlus;

  const initial = new Date(filterDate.initialDate);
  const final = new Date(filterDate.finalDate);

  const differenceInDays = (final - initial) / (1000 * 60 * 60 * 24);

  const transformData = (data, periodType = 'month') => {
    const transformedData = data.map(item => {
      function formatarData(data) {
        let dataFormatada = format(data, 'MMM yyyy', { locale: ptBR });

        dataFormatada =
          dataFormatada.charAt(0).toUpperCase() + dataFormatada.slice(1);

        return dataFormatada;
      }

      const date = parse(item.month_year, 'dd/MM/yy', new Date());
      if (!isValid(date)) {
        console.error(`Data inválida: ${item.name}`);
        return {
          ...item,
          month_year: 'Data inválida',
        };
      }

      if (periodType === 'day') {
        return {
          ...item,
          month_year:
            differenceInDays > 31 ? formatarData(date) : format(date, 'dd'),
        };
      }

      if (periodType === 'week') {
        const weekNumber = getISOWeek(date);
        return {
          ...item,
          month_year:
            differenceInDays > 24 * 7
              ? formatarData(date)
              : `S-${weekNumber < 10 ? `0${weekNumber}` : weekNumber}`,
        };
      }

      if (periodType === 'month') {
        return {
          ...item,
          month_year: formatarData(date),
        };
      }

      return {
        ...item,
        month_year:
          differenceInDays > 31 ? formatarData(date) : format(date, 'dd'),
      };
    });

    return transformedData;
  };

  const formatDate = isoDate => {
    if (isoDate) {
      const timeZone = 'America/Sao_Paulo';
      const zonedDate = utcToZonedTime(isoDate, timeZone);
      const formattedDate = format(zonedDate, 'HH:mm');
      return `Ontem às ${formattedDate}`;
    }
    return ' - ';
  };

  const fetchHistory = async () => {
    const res = await getHistoricoTratativas({
      ...dateHistory,
      type: historyFilterType,
    });
    setHistoryGraph(transformData(res?.data) || []);
  };

  const fetchJustified = async () => {
    setLoadingJustify(true);
    const res = await getJustified();
    setGraphJustied(res?.data || []);
    setLoadingJustify(false);
  };

  const fetchEquipe = async () => {
    setLoadingCard(true);
    const res = await getEquipe();
    const [firstHalf, secondHalf] = splitArray(res?.data || []);
    setCardOne(firstHalf);
    setCardTwo(secondHalf);
    setLoadingCard(false);
  };

  const fetchMovimentacoes = async () => {
    setLoadingMovimentacoes(true);
    const res = await getMovimentacoes({
      companies,
      ...filterDate,
      type: currentTab,
    });

    const porcentage = calculateTotalsAndPercentages(res?.data || []);
    if (currentTab === 'CRITICIDADE') setDesviosTratados(porcentage);

    setGraphMovimentacao(res?.data || []);
    if (res?.data) {
      const dataFromated = formatDataGraphPie(
        res.data,
        isProvider && !hasTorrePlus,
      );

      setGraphMovPie(dataFromated);
    }
    setLoadingMovimentacoes(false);
  };

  const fetchDesviosTratadosForaPrazo = async () => {
    setLoadingDtfp(true);
    const res = await getDesviosTratadosForaPrazo({ companies, ...filterDate });
    setDesviosTratadosForaPrazo(res?.data || []);
    setLoadingDtfp(false);
  };

  useEffect(() => {
    fetchHistory();
  }, [dateHistory, historyFilterType]);

  useEffect(() => {
    fetchMovimentacoes();
  }, [currentTab, filterDate, companies]);

  useEffect(() => {
    fetchDesviosTratadosForaPrazo();
  }, [filterDate, companies]);

  useEffect(() => {
    fetchHistory();
    fetchJustified();
    fetchEquipe();
    fetchMovimentacoes();
    fetchDesviosTratadosForaPrazo();
  }, []);

  const handleNavigate = url => navigate(url);

  const legends =
    hasTorrePlus || !isProvider ? legends_client : legends_empresa;

  return (
    <Container>
      {isProvider && (
        <>
          <div className="container-title">
            <div className="title-main">Estatísticas de tratativas</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <DateRangePicker
                onDate={date => setFilterDate(date)}
                dInt={filterDate.initialDate}
                dfim={filterDate.finalDate}
              />

              <div style={{ width: 300, marginLeft: 10, height: 30 }}>
                <SelectList
                  value={filterTratativa?.empresas}
                  handleApply={val => setCompanies(val)}
                  handleClean={() => setCompanies([])}
                  fieldName="empresas"
                  label="Filtrar por Empresas"
                  data={selects.empresas}
                  selectAll
                />
              </div>
            </div>
          </div>
          <Divider sx={{ marginBottom: 3, width: '70%' }} />
        </>
      )}
      <div className="container-header ">
        <div className="first-graph">
          <div className="title">Desvios tratados no prazo</div>
          <BarChartVertical data={desviosTratados} />
        </div>
        <div className="second-graph">
          <div className="top">
            <div className="top-title">Movimentações no período</div>
            <div className="container-tabs">
              <Tabs
                onChange={(e, val) => setCurrentTab(val)}
                value={currentTab}
                items={isProvider ? tabs_empresa : tabs_client}
              />
              {!isProvider && (
                <DateRangePicker
                  onDate={date => setFilterDate(date)}
                  dInt={filterDate.initialDate}
                  dfim={filterDate.finalDate}
                />
              )}
            </div>
          </div>
          <div className="middle">
            <div style={{ width: 300 }}>
              <PieGraph data={graphMoviPie} loading={loadingMovimentacoes} />
            </div>
            <div className="container-movi">
              <GraphBar
                data={graphMovimentacao}
                loading={loadingMovimentacoes}
                condition={isProvider && !hasTorrePlus}
              />
            </div>
          </div>

          <div className="footer">
            {legends.map(item => (
              <>
                <div
                  className="point"
                  style={{ backgroundColor: item.color }}
                />
                <div className="text">{item?.text || ''}</div>
              </>
            ))}
          </div>
        </div>
      </div>

      {!hasTorrePlus && isProvider && (
        <div className="graph-prazo">
          <div className="graph-prazo-title">
            Desvios concluídos fora do prazo
          </div>
          <GraphBarForaDoPrazo
            data={desviosTratadosforaPrazo}
            loading={loadingDtfp}
          />
        </div>
      )}
      {shouldDisplay && (
        <>
          <div className="minha-equipe-title">Minha equipe</div>
          <div className="content">
            {!loadingCard
              ? cardOne.map((card, index) => (
                  <div>
                    <div
                      className="card"
                      key={`${index}-one`}
                      onClick={() => handleNavigate(`/equipe/${card?.id}`)}
                    >
                      <div className="card-content">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              badgeColor={
                                card?.status === 'ATIVO' ? '#1BC5BD' : '#F64E60'
                              }
                              badgeBackgroundColor={
                                card?.status === 'ATIVO' ? '#1BC5BD' : '#F64E60'
                              }
                              variant="dot"
                            >
                              <Avatar
                                alt={cardTwo[index]?.nome}
                                src={cardTwo[index]?.foto}
                                sx={{
                                  width: 47,
                                  height: 47,
                                  boxShadow: '0px 3px 6px #00000029',
                                  backgroundColor: '#95AAC9',
                                }}
                              />
                            </StyledBadge>
                          </Stack>
                          <div className="name">{card.nome}</div>
                        </div>
                        <div>
                          <ArrowForwardIosIcon sx={{ color: '#4B5166' }} />
                        </div>
                      </div>
                      <div className="footer">
                        <div className="first-title">
                          Tempo médio de tratativa:{' '}
                          <span style={{ color: '#4B5166', fontWeight: 900 }}>
                            {card?.tempo_medio
                              ? `${card?.tempo_medio} minutos`
                              : ' - '}
                          </span>
                        </div>
                        <div className="secound-title">
                          Última tratativa:{' '}
                          <span style={{ color: '#95AAC9', fontWeight: 900 }}>
                            {formatDate(cardTwo[index]?.ultima_tratativa)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card"
                      key={`${index}-two`}
                      onClick={() =>
                        handleNavigate(`/equipe/${cardTwo[index]?.id}`)
                      }
                    >
                      <div className="card-content">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <StyledBadge
                              badgeColor={
                                cardTwo[index]?.status === 'ATIVO'
                                  ? '#1BC5BD'
                                  : '#F64E60'
                              }
                              badgeBackgroundColor={
                                cardTwo[index]?.status === 'ATIVO'
                                  ? '#1BC5BD'
                                  : '#F64E60'
                              }
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              variant="dot"
                            >
                              <Avatar
                                alt={cardTwo[index]?.nome}
                                src={cardTwo[index]?.foto}
                                sx={{
                                  width: 47,
                                  height: 47,
                                  boxShadow: '0px 3px 6px #00000029',
                                  backgroundColor: '#95AAC9',
                                }}
                              />
                            </StyledBadge>
                          </Stack>
                          <div className="name">
                            {cardTwo[index]?.nome || ' - '}
                          </div>
                        </div>
                        <div>
                          <ArrowForwardIosIcon sx={{ color: '#4B5166' }} />
                        </div>
                      </div>
                      <div className="footer">
                        <div className="first-title">
                          Tempo médio de tratativa:{' '}
                          <span style={{ color: '#4B5166', fontWeight: 900 }}>
                            {cardTwo[index]?.tempo_medio
                              ? `${cardTwo[index]?.tempo_medio} minutos`
                              : ' - '}
                          </span>
                        </div>
                        <div className="secound-title">
                          Última tratativa:{' '}
                          <span style={{ color: '#95AAC9', fontWeight: 900 }}>
                            {formatDate(cardTwo[index]?.ultima_tratativa)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : Array.from({ length: 10 }, () =>
                  Math.floor(Math.random() * 100),
                ).map((card, index) => (
                  <div key={index}>
                    <Skeleton
                      variant="rounded"
                      width={302}
                      height={139}
                      style={{ marginLeft: 20 }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={302}
                      height={139}
                      style={{ marginTop: 20, marginLeft: 20 }}
                    />
                  </div>
                ))}
          </div>
        </>
      )}
      <div className="see-more" onClick={() => handleNavigate(`/equipe`)}>
        Ver todos
      </div>
      <div className="card-bottom">
        <div style={{ width: hasTorrePlus || !isProvider ? '70%' : '100%' }}>
          <GraficoHitorico
            data={historyGraph}
            title="Histórico do tempo médio de tratativas"
            handleDate={date => setDateHistory(date)}
            changeFilter={e => setHistoryFiterType(e)}
          />
        </div>
        {(hasTorrePlus || !isProvider) && (
          <div>
            <GraficoBarraVertical
              data={graphJustied}
              dataKey1="motivo_exclusao"
              title="Justificativas de exclusão"
              isLoading={loadingjustify}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default TrativasDesvios;
