import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { startOfMonth, subMonths, subYears } from 'date-fns';

// components custom
import { PeriodPicker } from 'pages/Estatisticas/Components/PeriodPicker';
import { CustomizedSelect } from '../SectionPeriod';
// styles
import * as S from './styled';

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#95AAC9"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

export const GraficoHitorico = ({
  data = [],
  handleDate,
  title = '',
  filter = null,
  changeFilter = () => {},
}) => {
  const [finalDate, setFinalDate] = useState(new Date());
  const [initialDate, setIntitalDate] = useState(
    startOfMonth(subYears(new Date(), 1)),
  );

  const formatYAxis = tickItem => {
    return `${tickItem} min`;
  };

  const findMaxAverageMinutes = data => {
    return data.reduce(
      (max, entry) =>
        entry.average_minutes > max.average_minutes ? entry : max,
      data[0],
    );
  };

  return (
    <S.Container>
      <div className="container-filters">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <div className="title">{title}</div>
          <div className="flex-gap">
            <PeriodPicker
              finalDate={finalDate}
              initialDate={initialDate}
              period="lastYear"
              periodAdd="lastYear"
              onChange={date => {
                setFinalDate(date.finalDate);
                setIntitalDate(date.initialDate);
                handleDate(date);
              }}
            />
            {filter}
          </div>
        </div>
        <div>
          <CustomizedSelect onChange={changeFilter} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="month_year"
            height={70}
            tick={<CustomTick />}
            interval="auto"
          />
          <YAxis
            tickFormatter={formatYAxis}
            width={80}
            domain={[0, findMaxAverageMinutes(data)]}
          />

          <Line
            type="linear"
            dataKey="average_minutes"
            stroke="#0C12F2"
            dot={{ r: 0 }}
            activeDot={{ r: 0 }}
          />
          <Tooltip formatter={(value, name, props) => [value, `Tratativas:`]} />
        </LineChart>
      </ResponsiveContainer>
    </S.Container>
  );
};
