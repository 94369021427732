import { format, isValid, parse, getISOWeek } from 'date-fns';

const transformData = (data, periodType = 'day', differenceInDays = 0) => {
  const transformedData = data.map(item => {
    function formatarData(data) {
      let dataFormatada = format(data, 'MMM yyyy', { locale: ptBR });

      dataFormatada =
        dataFormatada.charAt(0).toUpperCase() + dataFormatada.slice(1);

      return dataFormatada;
    }

    const date = parse(item.name, 'dd/MM/yy', new Date());
    if (!isValid(date)) {
      console.error(`Data inválida: ${item.name}`);
      return {
        ...item,
        name: 'Data inválida',
      };
    }

    if (periodType === 'day') {
      return {
        ...item,
        name: differenceInDays > 31 ? formatarData(date) : format(date, 'dd'),
      };
    }

    if (periodType === 'week') {
      const weekNumber = getISOWeek(date);
      return {
        ...item,
        name:
          differenceInDays > 24 * 7
            ? formatarData(date)
            : `S-${weekNumber < 10 ? `0${weekNumber}` : weekNumber}`,
      };
    }

    if (periodType === 'month') {
      return {
        ...item,
        name: formatarData(date),
      };
    }

    return {
      ...item,
      name: differenceInDays > 31 ? formatarData(date) : format(date, 'dd'),
    };
  });

  return transformedData;
};

export { transformData };
