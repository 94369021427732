import React, { useState, useRef, useEffect } from 'react';
import { Bar, ContainerBar, Container, Tooltip, BarEmpty } from './styled';
import SelectMultiple from '../SelectMultiple';
import InfoCard from 'components/Cards/InfoCard';
import Skeleton from '@mui/material/Skeleton';

const BarraPercentual = ({
  data,
  selectOptions,
  value,
  handleSelect,
  isLoading = false,
}) => {
  const [criticality, setCriticality] = useState(null);
  const [shouldOpenSelect, setShouldOpenSelect] = useState(false);

  const handleSelectBar = item => {
    setCriticality(item.gravity);
    setShouldOpenSelect(true);
  };

  const handleSelectOptions = item => {
    handleSelect(item);
  };

  useEffect(() => {
    selectOptions.sort((a, b) => {
      if (a.criticality === criticality && b.criticality !== criticality) {
        return -1;
      }
      if (a.criticality !== criticality && b.criticality === criticality) {
        return 1;
      }

      const nomeA = a.criticality.toUpperCase();
      const nomeB = b.criticality.toUpperCase();
      if (nomeA < nomeB) {
        return -1;
      }
      if (nomeA > nomeB) {
        return 1;
      }

      return 0;
    });
  }, [criticality]);

  return (
    <>
      {!isLoading ? (
        <Container>
          {data.length > 0 && !data.every(item => item.percentage === 0) ? (
            <ContainerBar>
              {data.map(item => (
                <Bar
                  backgroundColor={item.color}
                  percentual={item.percentage}
                  onClick={() => handleSelectBar(item)}
                >
                  <Tooltip>
                    <span>{item.type}</span>
                    <p>{`${item.occurrences} de ${item.total} ocorrências`} </p>
                    <p>{`${item?.percentage?.toFixed(2)}% do total `} </p>
                  </Tooltip>
                </Bar>
              ))}
            </ContainerBar>
          ) : (
            <BarEmpty>
              Ainda não há dados suficientes para esta busca. Favor verificar
              seus filtros.
            </BarEmpty>
          )}
          <InfoCard message="Desvios de mesma criticidade com pontuações diferentes serão agrupados." />

          <div className="selector">
            <SelectMultiple
              value={value}
              shouldOpenSelect={shouldOpenSelect}
              handleChange={item => handleSelectOptions(item)}
              setShouldOpenSelect={() => setShouldOpenSelect(!shouldOpenSelect)}
              data={selectOptions}
              placeholder={
                <span
                  style={{
                    color: 'rgb(75, 81, 102)',
                    fontWeight: 900,
                    fontFamily: 'Texta',
                    fontStyle: 'normal',
                    fontSize: '18px',
                  }}
                >
                  Selecione a criticidade
                </span>
              }
            />
          </div>
        </Container>
      ) : (
        <>
          <Skeleton animation="wave" height={80} />
          <Skeleton animation="wave" height={80} />
        </>
      )}
    </>
  );
};

export { BarraPercentual };
