import React from 'react';
import { useLocation } from 'react-router-dom';

// styles
import { usePlans } from 'hooks/usePlans';

// pages
import { Detalhe } from './Detalhe';
import { DetalheDefault } from './DetalheDefault';
import { DetalheTorre } from './DetalheTorre';

export const NovaLista = () => {
  const { isProvider, hasTorre, hasTorrePlus } = usePlans();
  const location = useLocation();

  const isDefault = location.state.item?.is_default ?? false;

  if (isDefault) return <DetalheDefault item={location.state.item} />;
  if (hasTorre && !hasTorrePlus)
    return <DetalheTorre item={location.state.item} />;
  if (
    !isProvider &&
    location.state.item?.distribuidora &&
    !location.state.item?.id_empresa
  )
    return (
      <Detalhe
        item={{
          ...location.state.item,
          id: undefined,
          id_escalation_distribuidora: location.state.item.id,
          id_distribuidora: location.state.item.id_distribuidora,
          etapas: [],
        }}
        herdaCliente
      />
    );
  if (!isProvider && location.state.item?.id_escalation_distribuidora)
    return <Detalhe item={location.state.item} herdaCliente />;
  return <Detalhe item={location.state.item} />;
};
