import { defaultStaticRanges } from 'components/Header/DateRangePicker/defined';
import { differenceInHours, addDays, startOfDay, endOfDay } from 'date-fns';
import * as types from './types';

const today = endOfDay(new Date());
const last30 = startOfDay(addDays(new Date(), -30));

const INITIAL_STATE = {
  client: '',
  filial: '',
  filial_veiculo: '',
  operation: '',
  initialDate: last30,
  finalDate: today,
  dateLabel: 'Últimos 30 dias',
  filters: {},
};

export default function filter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_FILTER: {
      return { ...state, ...action.payload };
    }

    case types.HANDLE_APPLY_FILTER:
      return {
        ...state.filters,
        filters: {
          ...state.filters,
          [action.payload.page]: action.payload.filters,
        },
      };

    case types.SET_INITIAL_STATE_DATE: {
      const { dateLabel } = state;
      // Verifica se há um preset de data correspondente ao `dateLabel`
      const findedRange = dateLabel
        ? defaultStaticRanges.find(item => item.label === dateLabel)
        : null;

      if (findedRange) {
        const range = findedRange.range();
        const startDate = new Date(range.startDate);
        const endDate = new Date(range.endDate);

        const hasDifferentHours =
          Math.abs(differenceInHours(startDate, new Date(state.initialDate))) >
            6 ||
          Math.abs(differenceInHours(endDate, new Date(state.finalDate))) > 6;

        if (hasDifferentHours) {
          return {
            ...state,
            initialDate: range.startDate,
            finalDate: range.endDate,
          };
        }
      }

      // Retorna o estado original se nenhum range for encontrado ou não houver diferença
      return state;
    }

    default:
      return state;
  }
}
